<template>
    <f7-page name="listStore">
        <!-- Navbar -->
        <f7-navbar title="Artikelliste speichern" back-link> </f7-navbar>
        <f7-block-title>Speichern unter:</f7-block-title>
        <f7-list strong-ios dividers-ios inset-ios>
            <f7-list-input
                outline
                type="text"
                placeholder="Name der Liste"
                :value="listName"
                @input="listName = $event.target.value"
                clear-button
            ></f7-list-input>
        </f7-list>

        <div v-if="cartList.length > 0">
            <f7-block-title>Bestehende Liste überschreiben:</f7-block-title>
            <f7-list dividers-ios strong-ios outline-ios>
                <f7-list-item
                    v-for="item in cartList"
                    swipeout
                    @swipeout:deleted="deleteListItem(item)"
                    :key="item"
                    link="#"
                    :title="item"
                    no-chevron
                    @click="selectListItem(item)"
                >
                    <f7-swipeout-actions right>
                        <f7-swipeout-button delete>Löschen</f7-swipeout-button>
                    </f7-swipeout-actions>
                </f7-list-item>
            </f7-list>
        </div>

        <!-- Proceed button -->
        <f7-toolbar bottom :inner="false" class="toolbar-custom" no-shadow no-hairline>
            <f7-button fill large @click="saveList()" class="button-custom">Speichern</f7-button>
        </f7-toolbar>
    </f7-page>
</template>
<script>
export default {
    data() {
        return {
            listName: "",
            cartList: [],
        };
    },
    props: [],

    methods: {},
    computed: {},

    beforeMount() {
        let cartSaved = localStorage.getItem("cartSaved");
        // loop over all keys in cartSaved and store them as an array in cartList
        if (cartSaved) {
            this.cartList = Object.keys(JSON.parse(cartSaved)).sort();
        }
    },

    methods: {
        saveList: function() {
            console.log(this.$root.$data.order.cartSaved);

            // add current cart to cartSaved
            this.$root.$data.order.cartSaved[this.listName] = this.$root.$data.order.cart;
            let cartSaved = JSON.stringify(this.$root.$data.order.cartSaved);
            localStorage.setItem("cartSaved", cartSaved);

            // navigate to home
            this.$f7router.navigate("/");
        },

        selectListItem: function(item) {
            this.listName = item;
            console.log(item);
        },
        deleteListItem: function(item) {
            // Get current cart
            let cartSaved = JSON.parse(localStorage.getItem("cartSaved"));
            if (cartSaved == null) {
                this.$root.$data.order.cartSaved = {};
            }

            // // Delete item and save cartSaved
            delete cartSaved[item];
            delete this.$root.$data.order.cartSaved[item];
            localStorage.setItem("cartSaved", JSON.stringify(cartSaved));
        },
    },
};
</script>

<style scoped>
.list {
    margin-top: 0px;
}
</style>
