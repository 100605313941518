<template>
    <f7-page name="home">
        <!-- <f7-navbar no-shadow :title="$root.$data.env == 'prod' ? 'Bestellübersicht' : 'Bestellübersicht (Testsystem)'"> -->
        <f7-navbar>
            <f7-nav-left>
                <!-- save / restore list  -->
                <f7-link popover-open=".popover-menu">
                    <f7-icon ios="material:save" aurora="material:save" md="material:save" color="blue"></f7-icon>
                </f7-link>
                <f7-popover class="popover-menu">
                    <f7-list>
                        <f7-list-item v-if="$root.$data.order.cart.length > 0" link="/listStore/" popover-close title="Liste speichern" />
                        <f7-list-item link="/listLoad/" popover-close title="Liste laden" />
                    </f7-list>
                </f7-popover>
            </f7-nav-left>

            <!-- title -->
            <f7-nav-title
                :title="$root.$data.env == 'prod' ? 'Bestellübersicht' : 'Bestellübersicht (Testsystem)'"
                style="width: 100%; text-align: center"
            ></f7-nav-title>

            <!-- settings -->
            <f7-nav-right>
                <f7-link @click="$f7router.navigate('/settings/')">
                    <f7-icon f7="info_circle" color="blue"></f7-icon>
                </f7-link>
            </f7-nav-right>
        </f7-navbar>

        <!-- Login Screen -->
        <f7-login-screen :opened="showLogin" @loginscreen:closed="showLogin = false">
            <f7-page login-screen>
                <f7-login-screen-title>Login</f7-login-screen-title>
                <f7-list form>
                    <f7-list-input
                        label="Benutzername"
                        type="text"
                        placeholder="Dein Benutzername"
                        :value="$root.$data.credentials.username"
                        @input="$root.$data.credentials.username = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        label="Kennwort"
                        type="password"
                        placeholder="Dein Kennwort"
                        :value="$root.$data.credentials.password"
                        @input="$root.$data.credentials.password = $event.target.value"
                    ></f7-list-input>
                </f7-list>
                <f7-list>
                    <f7-list-button @click="signIn($root.$data.credentials.username, $root.$data.credentials.password)">Anmelden</f7-list-button>
                    <f7-block-footer
                        >Für das Erfassen einer Bestellung musst du dich zuerst anmelden. <br /><br />
                        Hervorragend AG
                    </f7-block-footer>
                </f7-list>
            </f7-page>
        </f7-login-screen>

        <!-- Show if cart is empty -->
        <f7-block v-if="$root.$data.order.cart.length === 0">
            <p>
                <center>
                    Die Liste enthält keine Einträge.
                    <br /><br />
                    Mit dem + Symbol unten rechts kannst du Bestellpositionen hinzufügen.
                </center>
            </p>
        </f7-block>

        <!-- Show if cart is not empty -->
        <shoppingCart></shoppingCart>

        <!-- Manual Scan: Invoke internal camera app -->
        <input type="file" id="manualScan" capture accept="image/*" style="display: none" @change="manualScanFile" />

        <!-- FAB Right Bottom -->
        <f7-fab position="right-bottom" slot="fixed" color="blue" style="margin-right: 10px; margin-bottom: 10px;">
            <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
            <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button @click="$f7router.navigate('/category/')">
                    <f7-icon size="20" ios="f7:rectangle_stack_fill" aurora="f7:rectangle_stack_fill" md="f7:rectangle_stack_fill"></f7-icon>
                </f7-fab-button>
                <f7-fab-button @click="$f7router.navigate('/new/')">
                    <f7-icon size="20" ios="f7:star_fill" aurora="f7:star_fill" md="f7:star_fill"></f7-icon>
                </f7-fab-button>
                <f7-fab-button v-if="settings.liveScan" @click="$f7router.navigate('/scan/')">
                    <f7-icon size="20" ios="f7:barcode" aurora="f7:barcode" md="f7:barcode"></f7-icon>
                </f7-fab-button>
                <f7-fab-button v-if="settings.manualScan" @click="manualScanStart">
                    <f7-icon size="20" ios="f7:barcode" aurora="f7:barcode" md="f7:barcode"></f7-icon>
                </f7-fab-button>
                <f7-fab-button v-if="settings.externalScan" @click="$f7router.navigate('/externalScan/')">
                    <f7-icon size="20" ios="f7:barcode" aurora="f7:barcode" md="f7:barcode"></f7-icon>
                </f7-fab-button>
                <f7-fab-button
                    @click="
                        $f7router.navigate('/manual/', {
                            props: { products },
                        })
                    "
                >
                    <f7-icon size="20" ios="f7:search" aurora="f7:search" md="f7:search"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>

        <!-- FAB Left Bottom -->
        <div
            v-if="$root.$data.order.cart.length > 0"
            slot="fixed"
            class="fab fab-extended fab-left-bottom color-blue"
            style="margin-left: 10px; margin-bottom: 10px;"
        >
            <a href="#" @click="$f7router.navigate('/selectCustomer/')">
                <i class="icon f7-icons if-not-md">cart</i>
                <i class="icon material-icons md-only">shopping_cart</i>
                <div class="fab-text">bestellen</div>
            </a>
        </div>
        <!-- <f7-fab position="left-bottom" slot="fixed" color="blue" style="margin-left: 10px; margin-bottom: 10px;">
            <f7-icon ios="f7:cart" aurora="f7:cart" md="material:shopping_cart"></f7-icon>
            <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button label="Artikel laden" @click="$f7router.navigate('/listLoad/')">
                    <f7-icon ios="material:list" aurora="material:list" md="material:list"></f7-icon>
                </f7-fab-button>
                <f7-fab-button v-if="$root.$data.order.cart.length > 0" @click="$f7router.navigate('/listStore/')" label="Artikel speichern">
                    <f7-icon ios="material:save" aurora="material:save" md="material:save"></f7-icon>
                </f7-fab-button>
                <f7-fab-button
                    v-if="$root.$data.order.cart.length > 0"
                    @click="$f7router.navigate('/selectCustomer/')"
                    label="Bestellung abschliessen"
                >
                    <f7-icon ios="material:shopping_cart" aurora="material:shopping_cart" md="material:shopping_cart"></f7-icon>
                </f7-fab-button>
            </f7-fab-buttons>
        </f7-fab> -->
    </f7-page>
</template>

<script>
// Import components
import shoppingCart from "../components/shoppingCart";

// Import Quagga scanner
import Quagga from "quagga";

const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();

export default {
    data() {
        return {
            products: null,
            showLogin: true,
            settings: {},
        };
    },
    props: ["title"],

    components: {
        shoppingCart,
    },

    methods: {
        manualScanStart: function() {
            let fileElem = document.getElementById("manualScan");
            console.log("manual scan clicked...");
            fileElem.click();
        },
        manualScanFile: async function() {
            let _this = this;
            let file = document.getElementById("manualScan").files[0];
            let data = await loadImage(file, {
                canvas: true,
                maxWidth: 1600,
                orientation: 6,
            });
            Quagga.decodeSingle({
                inputStream: {
                    type: "ImageStream",
                },
                locate: true,
                decoder: {
                    readers: ["ean_reader"],
                },
                locator: {
                    patchSize: "medium",
                    halfSample: true,
                },
                src: data.image.toDataURL("image/jpeg"),
            });
            Quagga.onProcessed(function(result) {
                console.log(result);
                try {
                    console.log("adding to cart...");
                    Quagga.stop();
                    const product = _this.$root.$data.products.find(function(product) {
                        return product.barcode === result.codeResult.code;
                    });
                    if (typeof product == "undefined") {
                        _this.showMessage("Kein Produkt mit diesem Barcode vorhanden! ", "error");
                    } else {
                        _this.$f7router.navigate("/addCart/" + encodeURIComponent(product.id) + "/");
                    }
                } catch {
                    Quagga.stop();
                    _this.showMessage("Barcode konnte nicht gelesen werden", "error");
                }
            });
        },
        removeByAttr: function(arr, attr, value) {
            var i = arr.length;
            while (i--) {
                if (arr[i] && arr[i].hasOwnProperty(attr) && arguments.length > 2 && arr[i][attr] === value) {
                    arr.splice(i, 1);
                }
            }
            return arr;
        },
        deleteFromCart: function(id) {
            // Get current cart
            let cart = JSON.parse(localStorage.getItem("cart"));
            if (cart == null) {
                this.$root.$data.order.cart = [];
            }
            // Prepare item
            console.log("deleting: " + id);

            // // Delete item
            this.removeByAttr(cart, "id", id);
            localStorage.setItem("cart", JSON.stringify(cart));
            this.$root.$data.order.cart = cart;
        },

        // Sign in: verify username and password
        signIn: function(username, password) {
            let _this = this;
            // Check if online
            if (!navigator.onLine) {
                this.showMessage("Anmeldung nicht möglich - Du bist offline!", "error");
                return;
            }

            // Check if fields are not empty
            if (!username || !password) {
                this.showMessage("Bitte Benutzername und Kennwort eingeben!", "error");
                return;
            }

            // prettier-ignore
            console.log('checking credentials...')
            fetch(this.$root.$data.endpoint + "/admin/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "A5C2AD00B7",
                },
                cache: "default",
                body: '{"action":"getData", "username": "' + username + '", "password":"' + password + '"}',
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === "ok") {
                        _this.$root.$data.credentials.lastLogin = new Date();
                        _this.$root.$data.credentials.username = _this.$root.$data.credentials.username.toLowerCase();

                        // Sort products and customers
                        const productsSorted = data.products.sort((a, b) => (a.id > b.id ? 1 : -1));

                        // Store to local storage
                        localStorage.setItem("credentials", JSON.stringify(_this.$root.$data.credentials));
                        localStorage.setItem("lastApiGet", Date.now());
                        localStorage.setItem("responsible", JSON.stringify(data.responsible));
                        localStorage.setItem("is_reseller", JSON.stringify(data.is_reseller));
                        localStorage.setItem("products", JSON.stringify(productsSorted));
                        localStorage.setItem("customers", JSON.stringify(data.customers));
                        localStorage.setItem("categories", JSON.stringify(data.categories));
                        // localStorage.setItem("barcodes", JSON.stringify(data.barcodes));

                        // Store to Vue
                        _this.$root.$data.lastApiGet = Date.now();
                        _this.$root.$data.products = productsSorted;
                        _this.$root.$data.customers = data.customers;
                        _this.$root.$data.categories = data.categories;
                        // _this.$root.$data.barcodes = data.barcodes;
                        _this.$root.$data.responsible = data.responsible;
                        _this.$root.$data.is_reseller = data.is_reseller;

                        _this.showLogin = false;

                        // Handle user settings
                        console.log("handing user settings...");
                        try {
                            if (data.userdata !== null) {
                                localStorage.setItem("settings", data.userdata);
                                this.settings = JSON.parse(data.userdata);
                                this.$root.$data.settings = this.settings;
                            }
                        } catch (e) {
                            console.log("no settings stored");
                        }
                    } else {
                        this.showMessage("Benutzername/Kennwort nicht korrekt!", "error");
                        _this.$root.$data.credentials.password = "";
                        _this.$root.$data.credentials.username = "";
                        localStorage.removeItem("credentials");
                    }
                });
        },
    },

    mounted() {
        let _this = this;

        // Read settings from storage
        let settings = JSON.parse(localStorage.getItem("settings"));

        // Refreshing credentials from storage
        console.log("reading credentials from storage...");
        let credentials = JSON.parse(localStorage.getItem("credentials"));
        if (credentials == null) {
            _this.showLogin = true;
        } else {
            _this.$root.$data.credentials.username = credentials.username;
            _this.$root.$data.credentials.password = credentials.password;
            _this.$root.$data.credentials.lastLogin = credentials.lastLogin;
            _this.showLogin = false;
        }

        // Load Data from Storage to Vue OR refresh if too old
        console.log("Load Data from Storage to Vue OR refresh if too old");
        if (_this.$root.$data.lastApiGet === "") {
            _this.$root.$data.lastApiGet = JSON.parse(localStorage.getItem("lastApiGet"));
            _this.$root.$data.responsible = JSON.parse(localStorage.getItem("responsible"));
            _this.$root.$data.products = JSON.parse(localStorage.getItem("products"));
            _this.$root.$data.customers = JSON.parse(localStorage.getItem("customers"));
            // _this.$root.$data.barcodes = JSON.parse(localStorage.getItem("barcodes"));
            _this.$root.$data.categories = JSON.parse(localStorage.getItem("categories"));
        }

        if (
            (_this.$root.$data.lastApiGet == null && navigator.onLine) ||
            (_this.$root.$data.lastApiGet != null && parseInt(_this.$root.$data.lastApiGet) + 86400 < Date.now() && navigator.onLine)
        ) {
            fetch(this.$root.$data.endpoint + "/admin/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "A5C2AD00B7",
                },
                cache: "default",
                body:
                    '{"action":"getData", "username": "' +
                    _this.$root.$data.credentials.username +
                    '", "password":"' +
                    _this.$root.$data.credentials.password +
                    '"}',
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === "ok") {
                        _this.$root.$data.credentials.lastLogin = new Date();
                        localStorage.setItem("credentials", JSON.stringify(_this.$root.$data.credentials));
                        localStorage.setItem("lastApiGet", Date.now());

                        // Sort products and customers
                        const productsSorted = data.products.sort((a, b) => (a.id > b.id ? 1 : -1));

                        console.log("saving data...");
                        localStorage.setItem("responsible", JSON.stringify(data.responsible));
                        localStorage.setItem("products", JSON.stringify(productsSorted));
                        localStorage.setItem("customers", JSON.stringify(data.customers));
                        // localStorage.setItem("barcodes", JSON.stringify(data.barcodes));
                        localStorage.setItem("categories", JSON.stringify(data.categories));
                        _this.$root.$data.responsible = data.responsible;
                        _this.$root.$data.products = data.products;
                        _this.$root.$data.customers = data.customers;
                        // _this.$root.$data.barcodes = data.barcodes;
                        _this.$root.$data.categories = data.categories;
                    }
                });
        }

        // Get cart and cartSaved data from local storage
        let cart = JSON.parse(localStorage.getItem("cart"));
        if (cart == null) {
            cart = [];
        }
        _this.$root.$data.order.cart = cart;

        let cartSaved = JSON.parse(localStorage.getItem("cartSaved"));
        if (cartSaved == null) {
            cartSaved = {};
        }
        _this.$root.$data.order.cartSaved = cartSaved;

        // Get manual scan settings
        console.log("loading settings from storage");
        let storageSettings = JSON.parse(localStorage.getItem("settings"));
        if (settings !== null) {
            this.settings = storageSettings;
            this.$root.$data.settings = this.settings;
        }
    },
};
</script>

<style scoped>
.list {
    margin-top: 0px;
}
ul::before {
    height: 0px;
}
p {
    margin-top: 50% !important;
    color: #959aa3;
}
.page-content {
    background-color: white;
}
</style>
<style>
html,
body {
    overflow: hidden;
    position: fixed;
    overscroll-behavior-y: contain;
    overscroll-behavior: contain;
}
.toast-text {
    margin: auto;
}
.md {
    --f7-fab-size: 48px;
    --f7-fab-extended-size: 48px;
}
</style>
