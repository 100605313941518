<template>
    <f7-page name="listLoad">
        <!-- Navbar -->
        <f7-navbar title="Artikelliste laden" back-link> </f7-navbar>

        <div v-if="cartList.length > 0">
            <f7-block-title>Liste auswählen:</f7-block-title>

            <f7-list strong-ios outline-ios dividers-ios>
                <f7-list-item
                    v-for="item in cartList"
                    radio
                    radio-icon="end"
                    name="demo-radio-end"
                    :title="item"
                    @click="selectListItem(item)"
                    swipeout
                    @swipeout:deleted="deleteListItem(item)"
                >
                    <f7-swipeout-actions right>
                        <f7-swipeout-button delete>Löschen</f7-swipeout-button>
                    </f7-swipeout-actions>
                </f7-list-item>
            </f7-list>
        </div>

        <f7-block v-else>
            <p>
                <center>
                    Keine Artikelliste vorhanden.
                    <br /><br />
                    Du hast bis jetzt noch keine Artikelliste abgespeichert.
                </center>
            </p>
        </f7-block>

        <!-- Proceed button -->
        <f7-toolbar bottom :inner="false" class="toolbar-custom" no-shadow no-hairline>
            <f7-button fill large @click="loadList()" class="button-custom">Laden</f7-button>
        </f7-toolbar>
    </f7-page>
</template>
<script>
export default {
    data() {
        return {
            listName: "",
            cartList: [],
        };
    },
    props: [],

    methods: {},

    computed: {},

    beforeMount() {
        let cartSaved = localStorage.getItem("cartSaved");
        // loop over all keys in cartSaved and store them as an array in cartList
        if (cartSaved) {
            this.cartList = Object.keys(JSON.parse(cartSaved)).sort();
        }
    },

    methods: {
        loadList: function() {
            // Check each item in list, if it is still deliverable
            let products = this.$root.$data.products;
            let checkedProducts = this.$root.$data.order.cartSaved[this.listName];

            checkedProducts = checkedProducts.filter((item) => {
                let product = products.find((product) => product.id === item.id);
                if (product == null || product.outofstock === true) {
                    this.$f7.dialog.alert(`Der Artikel "${item.name}" ist nicht lieferbar. Artikel wird nicht in übernommen.`);
                } else {
                    return item;
                }
            });

            // add current cart to cartSaved
            this.$root.$data.order.cart = checkedProducts;
            let cart = JSON.stringify(this.$root.$data.order.cart);
            localStorage.setItem("cart", cart);

            // navigate to home
            this.$f7router.navigate("/");
        },

        selectListItem: function(item) {
            this.listName = item;
            console.log(item);
        },
        deleteListItem: function(item) {
            // Get current cart
            let cartSaved = JSON.parse(localStorage.getItem("cartSaved"));
            if (cartSaved == null) {
                this.$root.$data.order.cartSaved = {};
            }

            // // Delete item and save cartSaved
            delete cartSaved[item];
            delete this.$root.$data.order.cartSaved[item];
            localStorage.setItem("cartSaved", JSON.stringify(cartSaved));
        },
    },
};
</script>

<style scoped>
.list {
    margin-top: 0px;
}
</style>
