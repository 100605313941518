import HomePage from "../pages/home.vue";
import ManualPage from "../pages/manual.vue";
import NewPage from "../pages/new.vue";
import LiveScan from "../pages/scan.vue";
import ExternalScan from "../pages/externalScan.vue";
import addCart from "../pages/addCart.vue";
import updateCart from "../pages/updateCart.vue";
import settings from "../pages/settings.vue";
import selectCustomer from "../pages/selectCustomer.vue";
import orderOptionalFields from "../pages/orderOptionalFields.vue";
import category from "../pages/category.vue";
import categoryDetail from "../pages/categoryDetail.vue";
import orderOverview from "../pages/orderOverview.vue";
import orderOverviewCart from "../pages/orderOverviewCart.vue";
import showCustomer from "../pages/showCustomer.vue";
import listStore from "../pages/listStore.vue";
import listLoad from "../pages/listLoad.vue";
import NotFoundPage from "../pages/404.vue";

// Routes
var routes = [
    {
        path: "/",
        component: HomePage,
    },
    {
        path: "/manual/",
        component: ManualPage,
    },
    {
        path: "/new/",
        component: NewPage,
    },
    {
        path: "/categoryDetail/:category",
        component: categoryDetail,
    },
    {
        path: "/category/",
        component: category,
    },
    {
        path: "/scan/",
        component: LiveScan,
    },
    {
        path: "/externalScan/",
        component: ExternalScan,
    },
    {
        path: "/addCart/:id/",
        component: addCart,
    },
    {
        path: "/updateCart/:id/:qty/:sampleQty/:barcode/:index",
        component: updateCart,
    },
    {
        path: "/settings/",
        component: settings,
    },
    {
        path: "/selectCustomer/",
        component: selectCustomer,
    },
    {
        path: "/orderOptionalFields/",
        component: orderOptionalFields,
    },
    {
        path: "/orderOverview/",
        component: orderOverview,
    },
    {
        path: "/showCustomer/",
        component: showCustomer,
    },
    {
        path: "/orderOverviewCart/",
        component: orderOverviewCart,
    },
    {
        path: "/listStore/",
        component: listStore,
    },
    {
        path: "/listLoad/",
        component: listLoad,
    },
    {
        path: "(.*)",
        component: NotFoundPage,
    },
];

export default routes;
